export const changeItemView = (dataManager) => (ev) => {
  ev.preventDefault()
  const isBigCardView = dataManager.data.get('bigCardView')
  const listBox = document.getElementById('puller-list')
  const initialClass = listBox.classList[listBox.classList.length - 1]
  if (!isBigCardView && initialClass === 'default') {
    listBox.classList.replace('default', 'bigview')
  }
  else if (isBigCardView && initialClass === 'bigview' ) {
    listBox.classList.replace('bigview', 'default')
  }
  dataManager.data.set('bigCardView', !isBigCardView)
}
