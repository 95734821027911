import cn from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import { getPageRoutes } from '../../api';

const ImgWithFallback = dynamic(() => import('../ImgWithFallback'));

const BrandItem = ({ photo, photoWebp, name }) => {
  return (
    <ImgWithFallback
      photoSrc={photo}
      title={name.name}
      alt={name.name}
      loading="lazy"
      photoWebp={photoWebp}
    />
  )
};

const SupplierItem = ({ photo, photoWebp, name }) => {
  const supplierName = name.nameEn || name.nameRu || name.name;
  return (
    <>
      <div className="item__img-wrapper item__img-wrapper--supplier">
        <ImgWithFallback
          photoSrc={photo}
          title={supplierName}
          alt={supplierName}
          loading="lazy"
          className="item__img"
          photoWebp={photoWebp}
        />
      </div>
      <p className="item__supplier-name">{supplierName}</p>
    </>
  );
};

const itemMapping = {
  brand: BrandItem,
  supplier: SupplierItem,
};

const Item = ({ data: { name, photo, webp_photo, resize_photo, id, name_ru, name_en }, type }) => {
  const link = getPageRoutes[type](id);

  const itemClassnames = cn('items__link', {
    'items__link--brand': type === 'brand',
    'items__link--supplier': type === 'supplier',
  });

  const renderItem = () => {
    const Component = itemMapping[type];
    return (
      <Component photo={resize_photo || photo} photoWebp={webp_photo} name={{ name, nameRu: name_ru, nameEn: name_en }} />
    );
  };

  return (
    <Link href={link}>
      <a className={itemClassnames}>{renderItem()}</a>
    </Link>
  );
};

export default Item;
