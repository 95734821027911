import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { initMap } from './mapInitializer';

const Wineries = () => {
  const { wineries } = useSelector((state) => state.data);
  const [ymap, setYmap] = useState(null);
  const [isYMapScriptLoaded, setIsYMapScriptLoaded] = useState(false);

  useEffect(() => {
    window.ymaps && window.ymaps.ready(initMap(wineries, setYmap));
    return () => {
      ymap && ymap.destroy();
    };
  }, [isYMapScriptLoaded]);

  return (
    <div style={{ padding: '0 1px 0 0' }}>
      <div id="map">
        <Script
          src="https://api-maps.yandex.ru/2.1/?lang=ru_RU"
          onLoad={() => setIsYMapScriptLoaded(true)}
          onError={() => alert('Яндекс.Карты не смогли загрузиться.')}
        />
      </div>
    </div>
  );
};

export default Wineries;
