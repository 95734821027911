import { getDate } from '../../utils';

export const getStatusLayout = (opening_hours, closing_hours) => {
  if (!opening_hours || !closing_hours) {
    return '';
  }

  const isActive = new Date() >= getDate(opening_hours) && new Date() <= getDate(closing_hours);

  return isActive ? (`
      <p class="winery-status active">Открыто</p>
      <span class='winery-status-divider'></span>
      <p class="winery-hours-detail">Закроется в ${closing_hours.slice(0,5)}</p>
  `) : (`
    <p class="winery-status disabled">Закрыто</p>
    <span class='winery-status-devider'></span>
    <p class="winery-hours-detail">Откроется в ${opening_hours.slice(0,5)}</p>
  `);
}
