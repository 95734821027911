export const getAddressWinery = (address) => {
  if (!address) {
    return '';
  }

  return `
   <p class="map__preview-address">
     ${address}
   </p>
 `;
}
