export const getSocialList = (link) => [
  {
    name: 'twitter',
    iconName: 'social__tw',
    href: `https://twitter.com/intent/tweet?text=${link}`,
  },
  {
    name: 'одноклассники',
    iconName: 'social__ok',
    href: `https://connect.ok.ru/offer?url=${link}`,
  },
  {
    name: 'вконтакте',
    iconName: 'social__vk',
    href: `https://vk.com/share.php?url=${link}`,
  },
  {
    name: 'telegram',
    iconName: 'social__tg',
    href: `https://t.me/share/url?url=${link}`,
  },
  {
    name: 'facebook',
    iconName: 'social__fb',
    href: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
  },
];
