export function getPicture(img) {
  if (img.length) {
    return `
     <img alt="" src="${img[0].image}" loading="lazy">
   `
  }
  return `
   <img alt="" src="/images/placeholder.png">
 `
}
