export const centerToPointOnMap = (dataManager, myMap) => () =>{
  const searchControl = myMap.controls.get('searchControl');
  const pullerList = document.getElementById('puller-list')
  const searchBar =  searchControl._layout._parentElement;

  myMap.setCenter(
    dataManager.data.get('center'),
    dataManager.data.get('zoom')
  );
  // const listBox = myMap.controls.get(7);
  // listBox.data.set('isFullSearch',true)
  pullerList.classList.remove('exposed','default','bigview')
  dataManager.data.set('bigCardView',false)
  pullerList.classList.add('closed')
  searchBar.classList.remove('cover')
}
