export const getWineryPageUrl = (id, name) => {
  if (!id) {
    return '';
  }

  return `
    <div class="popup__map-name">
      <a href="/wineries/${id}">${name}</a>
    </div>
 `;
};
