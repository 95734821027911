import ReactDOMServer from 'react-dom/server';

import { svgIcons } from '../Icon/svgIcons';
import { getSocialList } from '../ProductPosition/helpers/getSocialList';

export const getSocialItem = (id) => {
  const url = `${window.location.href}/${id}`;
  const socialList = getSocialList(url).map((item) => (`
    <li class="popup__map__share-item">
      <a href=${item.href} rel="noopener noreferrer" target="_blank" title=${item.name}>
         ${ReactDOMServer.renderToStaticMarkup(svgIcons[item.iconName]())}
      </a>
    </li>
  `)).join('');

  return `${socialList}`;
}
