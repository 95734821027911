import { getPreviewBigCard } from './getPreviewBigCard';

export function getPreviewWinery() {
  return ` {% if !data.bigCardView %}
    <li id="map__listbox-item-{{data.id}}">
      <div class="map__preview-winery">
      {% if data.isMobileOnly %}
        <div class="map__preview-info">
          <div class="map__preview-description">
              <div class="map__preview-name">
                    {{data.winery.name}}
              </div>
             <div class="map__preview-hours">
              {{data.winery.status | raw }}
              </div>
          <div class='map__preview-block'>
            <div class='map__preview-desc '>
               {{data.winery.describe| raw }}
                <div class='map__preview-sub'>
                  {{data.winery.address}}
                </div>
             </div>
               <div class="map__preview-picture">
                {{data.winery.image| raw }}
              </div>
          </div>
        </div>
      </div>
      {% else %}
       <div class="map__preview-info">
          <div class="map__preview-description">
            <a href="/wineries/{{data.winery.id}}">
              <div class="map__preview-name">
                {{data.winery.name}}
              </div>
            </a>
            <div class="map__preview-hours">
              {{data.winery.status | raw }}
            </div>
            {{data.winery.describe| raw }}
          </div>
          <div class="map__preview-picture">
             {{data.winery.image| raw }}
          </div>
          </div>
      {%endif%}
        <div class='map__preview-service'>
          <span class='map__preview-service-item' id='show-{{data.id}}'>Показать на карте</span>
          <span class='map__preview-service-item' id='change-{{data.id}}'>Услуги</span>
          <a class='map__preview-service-item' href="/wineries/{{data.winery.id}}">О винодельне</a>
        </div>
          <div class="map__preview-contacts">
            {{data.winery.phoneNumber| raw }}
            {{data.winery.urlInfo| raw }}
          </div>
          <hr class="map__preview-divider" />
       </div>
    </li>
    {%else%}
    ${getPreviewBigCard()}
    {%endif%}
  `;
}
