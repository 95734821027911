import ReactDOMServer from 'react-dom/server';

import { svgIcons } from '../Icon/svgIcons';

import { getPhoneNumber } from './getPhoneNumber';
import { getPicture } from './getPicture';
import { getServices } from './getServices';
import { getShareWrapper } from './getShareWrapper';
import { getStatusLayout } from './getStatusLayout';
import { getUrl } from './getUrl';
import { getWineryPageUrl } from './getWineryPageUrl';

export const getHintWinery = (winery) => {
  const { id, closing_hours, opening_hours, phone, url, images, services, name } = winery;

  return `
    <div class="popup__map">
      <div class="popup__map-picture">
        ${getPicture(images)}
      </div>
      <div class="popup__map-description">
        <div class="popup__map-content">
           ${getWineryPageUrl(id, name)}
          <div class="popup__map-share-wrapper">${getShareWrapper(id)}</div>
        </div>
        <div class="popup__map-services">
          ${getServices(services)}
        </div>
        <div class="popup__map-hours">
          ${getStatusLayout(opening_hours, closing_hours)}
        </div>
        ${getPhoneNumber(phone)}
        ${getUrl(url)}
      </div>
      <div class="popup__map-close-btn">
        ${ReactDOMServer.renderToStaticMarkup(svgIcons['close-balloon-button']())}
      </div>
    </div>
  `;
};
