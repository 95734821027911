export function getPreviewBigCard() {
  return `
   <li id="map__listbox-item-{{data.id}}">
    <div class='map__preview__bigview'>
          <div class='map__preview__bigview-picture'>
            {{data.winery.image | raw}}
          </div>
          <div class='map__preview__bigview-name'>
             {{data.winery.name}}
          </div>
          <div class='map__preview__bigview-description'>
           {{data.winery.describe | raw }}
          </div>
          <ul class='map__preview__bigview-tags'>
             {% for service in data.winery.services %}
              <li id='map__preview__bigview-tag-{{service.id}}'>
                     <img src='{{service.photo}} ' alt=''/>
                     <p>{{service.name}}</p>
              </li>
             {% endfor %}
          </ul>
          <div class='map__preview__bigview-address'>
             {{data.winery.address}}
          </div>
          <div class="map__preview-hours">
              {{data.winery.status | raw }}
          </div>
          <div class='map__preview__bigview-service'>
            <span class='map__preview-service-item' id='show-{{data.id}}'>Показать на карте</span>
             <span style='display: none' class='map__preview-service-item' id='change-{{data.id}}'>Услуги</span>
            <a class='map__preview-service-item' href="/wineries/{{data.winery.id}}">О винодельне</a>
          </div>
          <div class="map__preview__bigview-contacts">
            {{data.winery.phoneNumber| raw }}
            {{data.winery.urlInfo| raw }}
          </div>
    </div>
  </li>
  `
}
