import { isMobileOnly } from 'react-device-detect';
import ReactDOMServer from 'react-dom/server';

import { debounce } from '../../utils/debounce';
import { svgIcons } from '../Icon/svgIcons';

import CustomSearchProvider from './CustomSearchProvider';
import { centerToPointOnMap } from './centerToPointOnMap';
import { changeItemView } from './changeItemView';
import { createDropdown } from './createDropdown';
import { getAddressWinery } from './getAddressWinery';
import { getHintWinery } from './getHintWinery';
import { getPhoneNumber } from './getPhoneNumber';
import { getPicture } from './getPicture';
import { getPreviewWinery } from './getPreviewWinery';
import { getStatusLayout } from './getStatusLayout';
import { getUrl } from './getUrl';
import { showSocialItems } from './showSocialItems';

export const initMap = (wineries, setYmapCallback) => () => {
  const ymaps = window.ymaps;
  const points = [];
  // определение центра карты при создании
  const { centerLat, centerLon } = wineries.reduce((accumulate, winery, index) => {
    const { lat: geo_lat_string, lon: geo_lon_string } = winery.point;
    const geo_lat = parseFloat(geo_lat_string);
    const geo_lon = parseFloat(geo_lon_string);
    points.push({ coordinates: [geo_lat_string, geo_lon_string], name: winery.name });

    accumulate.centerLat += geo_lat;
    accumulate.centerLon += geo_lon;

    if (index === wineries.length - 1) {
      accumulate.centerLat /= wineries.length;
      accumulate.centerLon /= wineries.length;
    }

    return accumulate;
  }, { centerLat: 0, centerLon: 0 });

  // создание компонента карты
  const myMap = new ymaps.Map('map', {
    center: [centerLat, centerLon],
    zoom: 5,
  }, {
    autoFitToViewport: 'always',
    searchControlPlaceholderContent: 'Адрес или название винодельни',
    searchControlSize: 'large',
    searchControlMaxWidth: 360,
    searchControlFitMaxWidth: true,
    searchControlNoCentering: isMobileOnly,
  });
  // Создадим собственный макет выпадающего списка.
  let ListBoxLayout = ymaps.templateLayoutFactory.createClass(
      createDropdown(isMobileOnly),
      {
        build: function() {
          ListBoxLayout.superclass.build.call(this);
          this.childContainerElement = document.querySelector('#listbox');

          this.events.fire('childcontainerchange', {
            newChildContainerElement: this.childContainerElement,
            oldChildContainerElement: null
          });
        },
        // чтобы не перестраивал элементы списка для мобильной версии карт
        rebuild: function() {
          if (!isMobileOnly) {
            ListBoxLayout.superclass.rebuild.call(this);
          }
        },

        getChildContainerElement: function () {
          return this.childContainerElement;
        },

        clear: function () {
          this.events.fire('childcontainerchange', {
            newChildContainerElement: null,
            oldChildContainerElement: this.childContainerElement
          });
          this.childContainerElement = null;
          ListBoxLayout.superclass.clear.call(this);
        }
      }),
    // создание собственных элементов списка
    ListBoxItemLayout = ymaps.templateLayoutFactory.createClass(
      getPreviewWinery(),{
        build : function () {
          ListBoxItemLayout.superclass.build.call(this);
          const dataManager =  this.getData();
          const id = dataManager.data.get('id')

          const centerButton = document.getElementById(`show-${id}`)
          const changeViewButton = document.getElementById(`change-${id}`)
          // события для кнопок элемента списка в мобильном виде

          //центрировать точку на карте
          centerButton.addEventListener('click', centerToPointOnMap(dataManager,myMap))
          //раскрыть элемент/сложить элемент по клику
          changeViewButton.addEventListener('click', changeItemView(dataManager))

        },
        clear: function (){
          ListBoxItemLayout.superclass.clear.call(this);
        },

      }
    ),
    listBoxItems = [
      ...wineries.map((winery, id) => {
        const listBoxItem = new ymaps.control.ListBoxItem({
          data: {
            center: [
              winery.point.lat,
              winery.point.lon,
            ],
            //сохраняем данные о винодельне в элементе
            winery: {
              ...winery,
              status: getStatusLayout(winery.opening_hours, winery.closing_hours),
              describe: getAddressWinery(isMobileOnly
                ? winery.description.split(' ').slice(0,12).join(' ')
                : winery.address
              ),
              image: getPicture(winery.images),
              phoneNumber: getPhoneNumber(winery.phone),
              urlInfo: getUrl(winery.url)
            },
            isMobileOnly,
            id,
            bigCardView: false,
            zoom: 13,
          },
        })
        //смена состояния по двойному нажатию на кнопку
        listBoxItem.events.add('dblclick',changeItemView(listBoxItem))
        return listBoxItem
      }),
    ],
    // создание компонента кастомного списка
    listBox = new ymaps.control.ListBox({
      items: isMobileOnly ? [listBoxItems[0]]: listBoxItems,
      data: {
        state: 'exposed',
        isFullSearch: true,
      },
      options: {
        layout: ListBoxLayout,
        itemLayout: ListBoxItemLayout
      },
      state: {
        expanded: true,
      }
    });
  listBox.events.add('click', function (e) {
    var item = e.get('target');

    if (item !== listBox && !isMobileOnly) {
      myMap.setCenter(
        item.data.get('center'),
        item.data.get('zoom')
      );
    }
  });

  myMap.controls.add(listBox, {float: 'left'});
  //установка собственного поисковика
  myMap.options.set( 'searchControlProvider', new CustomSearchProvider(points, listBox))

  const searchControl = myMap.controls.get('searchControl');

  setYmapCallback(myMap);
  // ручное удаление элементов из списка
  const removeFromList = (...idsToStay) => {
    listBox.removeAll()
    idsToStay.forEach((id) => {
      const elem = listBoxItems.find((elem) => elem.data.get('winery.id') === id)
      listBox.add(elem)
    })
  }
  // востановление исходного списка
  const restoreDefaultList = () => {
    if (listBox.getLength() === listBoxItems.length) {
      return
    }
    const listBoxElement = document.getElementById('listbox');
    listBox.removeAll()
    listBoxItems.forEach((item) => listBox.add(item))
    listBoxElement.scrollTo({top:0})
  }
  // Создаем собственный макет балуна кластера.
  const customBalloonContentLayout = ymaps.templateLayoutFactory.createClass(`
    <div class="cluster-balloon">
      <div class="cluster-balloon__list">
        <ul class="dots-list">
          {% for geoObject in properties.geoObjects %}
            <li>
              <div
                data-placemarkId="{{ geoObject.properties.iconContent.id }}"
                class="dots-list__item"
              >
                {{ geoObject.properties.balloonProps.name|raw }}
              </div>
            </li>
          {% endfor %}
        </ul>
      </div>
      <div class="cluster-balloon__content">
        {{ properties.geoObjects[0].properties.balloonContent|raw }}
      </div>
      <div class="cluster-balloon__close-btn">
        ${ReactDOMServer.renderToStaticMarkup(svgIcons['close-balloon-button']())}
      </div>
    </div>
  `);

  // Создание кластера
  const clusterer = new ymaps.Clusterer({
    clusterDisableClickZoom: true,
    clusterOpenBalloonOnClick: true,
    // Устанавливаем режим открытия балуна.
    // В данном примере балун никогда не будет открываться в режиме панели.
    clusterBalloonPanelMaxMapArea: 0,
    // Устанавливаем собственный макет.
    clusterBalloonContentLayout: customBalloonContentLayout,
    // Отключаем кнопку закрытия балуна.
    balloonCloseButton: false,
    balloonAutoPan: !isMobileOnly
  });
  // Добавляем обработку нажатий по элементам списка и кнопке закрытия балуна.
  // Обработка клика по маркеру шарика
  clusterer.balloon.events.add('open', (ev) => {
    if (isMobileOnly) {
      const objects = ev.get('cluster').properties.get('geoObjects')

      clusterer.balloon.close(true)
      // функционал поиска по выбранному класстеру
/*      listBox.data.set('isFullSearch',false)*/

      removeFromList(...objects.map(item => item.properties.get('iconContent.wineryId')))


      const listBoxElement = document.getElementById('listbox');
      listBoxElement.scrollTo({top: 0})

      const searchBar =  searchControl._layout._parentElement;
      const pullerList = document.getElementById('puller-list')
      pullerList.classList.remove('exposed','default','closed','bigview')
      pullerList.classList.add('exposed')
      searchBar.classList.add('cover')
      return
    }
    const listElement = document.querySelector('.dots-list');
    listElement.querySelector('.dots-list__item').classList.add('dots-list__item--active');

    listElement.addEventListener('click', (event) => {
      const itemElement = event.target;
      const wineryId = event.target.getAttribute('data-placemarkId');
      if (!wineryId) return;

      listElement
        .querySelectorAll('.dots-list__item')
        .forEach((child) =>
          child.classList.remove('dots-list__item--active')
        );
      itemElement.classList.add('dots-list__item--active');

      document.querySelector('.cluster-balloon__content').innerHTML =
        getHintWinery(wineries[wineryId]);
    });
    document.querySelector('.cluster-balloon__close-btn').addEventListener('click', () => {
      clusterer.balloon.close(true);
    });
  });

  const MyDotContentLayout = ymaps.templateLayoutFactory.createClass(`
    <div class="dot__icon-content dot_$[properties.iconContent.id]">
      $[properties.iconContent.name]
      <svg width="20" height="8" style="position: absolute; top: calc(100% - 2px); left: calc(50% - 10px)">
        <polygon points="0,0 20,0 10,8" fill="#ffffff" />
      </svg>
    </div>
  `);

  // TODO обсудить переход на кластеризуеимые точки https://yandex.ru/dev/maps/jsbox/2.1/object_manager
  wineries.forEach((winery, id) => {
    const placemark = new ymaps.Placemark([
      winery.point.lat,
      winery.point.lon,
    ], {
      iconContent: {
        name: winery.name,
        id,
        wineryId: winery.id,
      },
      balloonProps: {
        name: winery.name,
      },
      balloonContent: getHintWinery(winery),
    }, {
      // Отключаем кнопку закрытия балуна.
      balloonCloseButton: false,
      // Балун будем открывать и закрывать кликом по иконке метки.
      hideIconOnBalloonOpen: false,
      // https://yandex.ru/dev/maps/jsbox/2.1/icon_customImage
      // Опции.
      // Тип макета.
      iconLayout: 'default#imageWithContent',
      // Своё изображение иконки метки.
      iconImageHref: 'map_dot.svg',
      // Размеры метки.
      iconImageSize: [16, 16],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-8, -8],
      // Смещение слоя с содержимым относительно слоя с картинкой.
      iconContentOffset: [-45, calcDotContentYOffset(winery.name)],
      // Макет содержимого.
      iconContentLayout: MyDotContentLayout,
    })

    placemark.events.add('balloonopen', (event) => {
      // закрытие для мобилок
      if (isMobileOnly) {
        placemark.balloon.close(true)
        const pullerList = document.getElementById('puller-list');
        pullerList.classList.remove('exposed','default','closed','bigview')
        pullerList.classList.add('bigview')
        removeFromList(winery.id)
        // listBox.data.set('isFullSearch',true)
        listBox.each((item) => item.data.set('bigCardView',true))
        return
      }

      const list = document.querySelector('.popup__map__share-items');
      const button = document.querySelector('.popup__map__share-btn');
      const closeButton = document.querySelector('.popup__map-close-btn');
      button.addEventListener('click', () => showSocialItems(list,button));
      closeButton.addEventListener('click', () => placemark.balloon.close(true));
    });
    clusterer.add(placemark);
  });

  myMap.geoObjects.add(clusterer);

  // Скрывает еще одну метку при выборе результата поиска,
  // т.к. метки коллекции myCollection уже добавлены на карту.

  searchControl.options.set('noPlacemark', true);
  searchControl.options.set('noPopup', false);
  searchControl.events.add('load', (event) => {
    if (!event.get('skip') && searchControl.getResultsCount() === 1) {
      searchControl.showResult(0);
    }
  });

  ymaps.ready(() => {
    // элементы
    const input = document.querySelector('.ymaps-2-1-79-searchbox-input');
    const button = document.querySelector('.ymaps-2-1-79-searchbox__button-cell');
    const searchBar =  searchControl._layout._parentElement;

    const puller = document.getElementById('puller')
    const pullerList = document.getElementById('puller-list')

    // константы
    let removeFlag = false;
    let initialClass = null;
    const height = window.innerHeight;
    const topLimit = 57;
    const bottomLimit = 28;
    const defaultThreshold =  277;
    const defaultBigViewThreshold = 543;

    const updateListBoxItem = (scrollToItemId) => {
      if (listBox.getLength() !== 1) {
        removeFromList(scrollToItemId)
      }
    }
    const closeListBoxItem = () => {
      const items = listBox.getAll()
      items.forEach((item) => {
        item.data.set('bigCardView',false)
      })
    }

    input.addEventListener('focusin', () => button.classList.add('ymaps-2-1-79-_focused'))

    input.addEventListener('focusout', () => button.classList.remove('ymaps-2-1-79-_focused'))

    puller.addEventListener('touchstart' ,(event) => {
      event.preventDefault()
      //снятие классов состояния при движение
      removeFlag = true
      initialClass = pullerList.classList[pullerList.classList.length - 1]    // класс состояния списка
    })

    puller.addEventListener('touchend', (evt) => {
      evt.preventDefault()

      const pageY = evt.changedTouches[0].pageY;
      const chosenThreshold = initialClass === 'bigview' ? defaultBigViewThreshold : defaultThreshold
      const computedBottomRange = height - chosenThreshold - bottomLimit
      const scrollToItemId = listBox.getAll()[0].data.get('winery.id')
      // const scrollElementPosition = Math.round(pullerList.lastElementChild.scrollTop / 278)

      pullerList.classList.remove('exposed','default','closed','bigview')

      if (pageY >= computedBottomRange) {
        // вниз

        //обновлять ли список
        updateListBoxItem(scrollToItemId)
        //поиск по всем точкам
        // listBox.data.set('isFullSearch',true)

        if (Math.floor(chosenThreshold / 2) > (height - pageY))  {
          // скрыть все элементы
          closeListBoxItem()
          pullerList.classList.add('closed')

        }
        else  {
          initialClass === 'bigview' ? pullerList.classList.add('bigview'): pullerList.classList.add('default')
        }
      }
      else  {
        // вверх
        if (Math.floor((height - chosenThreshold - topLimit) / 2) + chosenThreshold <= (height - pageY )) {
          pullerList.classList.add('exposed')

          if (initialClass !== 'exposed') {
            restoreDefaultList()
          }
          searchBar.classList.add('cover')
        }
        else {
          //обновлять ли список
          updateListBoxItem(scrollToItemId)
          //поиск по всем точкам
          // listBox.data.set('isFullSearch',true)

          if (initialClass === 'bigview') {
            pullerList.classList.add('bigview')
          }
          else {
            // скрыть все элементы
            closeListBoxItem()
            pullerList.classList.add('default')

          }
        }

      }
    })

    puller.addEventListener('touchmove', (event) => {
      event.preventDefault()

      const pageY = event.changedTouches[0].pageY
      const height = window.innerHeight;
      ///57 - header height in mobile version
      if (pageY <= topLimit) {
        return
      }
      else if (height - pageY <= bottomLimit) {
        return;
      }

      pullerList.style.bottom = `calc(100vh - ${pageY}px - ${bottomLimit}px)`
      //снятие классов состояния при движение
      if (removeFlag) {
        pullerList.classList.remove('exposed','default','closed','bigview')
        searchBar.classList.remove('cover')
        removeFlag = false
      }
    })

  })
  // Открываем боковую панель и добавляем подсветку при выборе определённого варианта
  searchControl.events.add('resultshow', (event) => {
    const name = searchControl.getResultsArray()[
      event.get('index')
    ].properties.get('name');

    const wineryIndex = wineries.findIndex((winery) => winery.name === name);

    const mobileScroll = () => {
      const listBoxItemElement = document.getElementById(`map__listbox-item-${wineryIndex}`).firstElementChild;
      const listBoxElement = document.getElementById('listbox');
      const pullerList = document.getElementById('puller-list')
      pullerList.classList.remove('exposed','default','closed','bigview')
      pullerList.classList.add('exposed')
      const search = searchControl._layout._parentElement;
      search.classList.toggle('cover',true)
      listBoxElement.scrollTo({top: listBoxItemElement.offsetTop - 84});
    }
    // скролл в зависимости от мобилки и состояния поисковой строки
    if (isMobileOnly) {
      if (listBox.data.get('isFullSearch')) {
        restoreDefaultList()
        setTimeout(mobileScroll, 100)
        return
      }
      mobileScroll()
    }
    else {
      const listBoxItemElement = document.getElementById(`map__listbox-item-${wineryIndex}`).firstElementChild;
      const listBoxElement = document.getElementById('listbox');
      scrollEndListener = debounce(() => highlight(listBoxItemElement), 100);
      scrollingElement = listBoxElement;

      listBox.expand();

      if (listBoxElement.scrollTop < listBoxItemElement.offsetTop - 59) {
        listBoxElement.scrollTo({top: listBoxItemElement.offsetTop - 59});
        listBoxElement.addEventListener('scroll', scrollEndListener);
      }
      else {
        highlight(listBoxItemElement);
      }
    }

  });
};

let scrollEndListener = null;
let scrollingElement = null;

const highlight = (element) => {
  let defaultBG = element.style.backgroundColor;
  let defaultTransition = element.style.transition;

  element.style.transition = 'background 1.333s';
  element.style.backgroundColor = '#FDFF47';

  if (scrollEndListener && scrollingElement) {
    scrollingElement.removeEventListener('scroll', scrollEndListener);
    scrollingElement = null;
    scrollEndListener = null;
  }

  setTimeout(() => {
    element.style.backgroundColor = defaultBG;
    setTimeout(() => {
      element.style.transition = defaultTransition;
    }, 1333);
  }, 1600);
}

const calcDotContentYOffset = (text) => {
  const divElement = document.createElement('div');
  const textContent = document.createTextNode(text);
  divElement.appendChild(textContent);
  divElement.style.font = '13px Arial,sans-serif';
  divElement.style.textAlign = 'center';
  divElement.style.padding = '3px';
  divElement.style.width = '106px';
  divElement.style.borderRadius = '8px';
  document.body.appendChild(divElement);
  const divElementOffsetHeight = divElement.offsetHeight;
  document.body.removeChild(divElement);
  if (divElementOffsetHeight >= 67) { return -70; }
  if (divElementOffsetHeight >= 52) { return -55; }
  if (divElementOffsetHeight >= 36) { return -40; }
  if (divElementOffsetHeight >= 21) { return -25; }
}

