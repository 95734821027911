import { getSocialItem } from './getSocialItem';

export const getShareWrapper = (id) => (`
   <div class="popup__map__share-wrapper">
    <button title="Поделиться" class="popup__map__share-btn">
      <span class="icon-share" />
    </button>
    <ul class="popup__map__share-items">
      ${getSocialItem(id)}
    </ul>
  </div>
`);
