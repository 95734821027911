export const createDropdown = (isMobileOnly) => (`
  <button
    id='listbox-header'
    class='btn btn-success dropdown-toggle'
    data-toggle='dropdown'
    style='border-radius:{% if state.expanded %}0 5px 5px 0{% else %}3px{% endif %};'
  >
    <span
      class='caret'
      style='transform: {% if state.expanded %}rotate(0deg){% else %}rotate(180deg){% endif %};'
    />
  </button>
  <div id='puller-list' class='dropdown-menu default'  style='display:${isMobileOnly ? 'block' : '{% if state.expanded %}block{% else %}none{% endif %}'} ;'>
  <div id='puller' class='dropdown-header'></div>
    <ul
    id='listbox'
    class='dropdown-list' role='menu' aria-labelledby='dropdownMenu'
  />
</div>

`);
