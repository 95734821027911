export const getServices = (services) => {
  if (!services.length) {
    return '';
  }

  return services.map((service) => (
    `
      <div class="popup__map-service">
        <picture>
          ${service.webp_photo ? `<source srcSet=${service.webp_photo} type="image/webp" />` : ''}
          <img src=${service.photo ? service.photo : '/placeholder.png'} alt=""/>
        </picture>
        <div class="popup__map-service-description">
          ${service.name}
        </div>
      </div>
    `
  )).join('');
};
