import item from '../Items/Item';

function CustomSearchProvider(points,listbox) {
  this.points = points;
  this.listbox = listbox
}

// Провайдер ищет по полю name стандартным методом String.ptototype.indexOf.
CustomSearchProvider.prototype.geocode = function (request, options) {
  const ymaps = window.ymaps;

  const deferred = new ymaps.vow.defer();
  const geoObjects = new ymaps.GeoObjectCollection();

  const points = [];
  // Ищет в свойстве name каждого элемента массива.
  for (let i = 0, l = this.points.length; i < l; i++) {
    const point = this.points[i];
    if (point.name.toLowerCase().indexOf(request.toLowerCase()) !== -1) {
      points.push(point);
    }
  }

  // Добавляет точки в результирующую коллекцию.
  for (let i = 0, l = points.length; i < l; i++) {
    const { coordinates, name } = points[i];

    geoObjects.add(new ymaps.Placemark(coordinates, {
      name: name,
      description: name,
      balloonContentBody: '<p>' + name + '</p>',
      boundedBy: [coordinates, coordinates],
    }));
  }

  deferred.resolve({
    // Геообъекты поисковой выдачи.
    geoObjects: geoObjects,
    // Метаинформация ответа.
    metaData: {
      geocoder: {
        // Строка обработанного запроса.
        request: request,
        // Количество найденных результатов.
        found: geoObjects.getLength(),
      }
    }
  });

  // Возвращает объект-обещание.
  return deferred.promise();
};

// Провайдер ищет по полю name стандартным методом String.ptototype.indexOf.
CustomSearchProvider.prototype.suggest = function (request, options) {
  const ymaps = window.ymaps;

  const deferred = new ymaps.vow.defer();
  const suggestions = [];
  const isFullSearch = this.listbox.data.get('isFullSearch')
  const items = isFullSearch ? this.points.map(({name}) => name)
    : this.listbox.getAll().map(item => item.data.get('winery.name'))
  // Ищет в свойстве name каждого элемента массива.
  for (let i = 0, l = items.length; i < l; i++) {
    const point = items[i];
    if (point.toLowerCase().indexOf(request.toLowerCase()) !== -1) {
      const name = point;
      suggestions.push({
        displayName: name,
        value: name,
      });
    }
  }

  deferred.resolve(suggestions);

  // Возвращает объект-обещание.
  return deferred.promise();
};

export default CustomSearchProvider;
