export const getPhoneNumber = (phone) => {
  if (!phone) {
    return '';
  }

  return `
   <a class="map__preview-tel" href="tel:${phone}">
     <span class="icon icon-telephone"></span>
     <span>${convertPhoneNumber(`${phone}`)}</span>
   </a>
 `
};

export const convertPhoneNumber = (phoneNumber) => phoneNumber.replace(/(.)(...)(...)(..)(..)/, '+$1 $2 $3 $4 $5');
